import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Home from "./home";
import { ToastContainer,} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <>

    <ToastContainer/>
    <div style={{backgroundColor:"Background"}}>
      <Routes>

        <Route path='/' element={<Home />} />

      </Routes>
    </div>
    </>




  );
}

export default App;
