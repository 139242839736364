import { useEffect, useState } from "react";
import "./App.css";
import { FaUser } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import axios from "axios";
import config from "./config/config.json";
import { toast } from "react-toastify";
import { Audio } from 'react-loader-spinner'


function Home() {
  const [userName, setUserName] = useState();
  const [tckn, setTckn] = useState();
  const [amount, setAmount] = useState();
  const [AccountName, setAccountName] = useState();
  const [Iban, setIban] = useState();
  const [Receipt, setReceipt] = useState();
  const [step, setStep] = useState(1);
  const [transaction, setTransaction] = useState();
  const [showStep1Loading, setShowStep1Loading] = useState(false);


  function handelChange(e) {
    e.preventDefault();
    if (step === 1) {

      function generateRandomObjectId() {
        const timestamp = (new Date().getTime() / 1000 | 0).toString(16); // 4-byte timestamp
        const randomValue = Array.from(crypto.getRandomValues(new Uint8Array(3))).map(b => b.toString(16)).join(''); // 3-byte random value
        const machineId = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'); // 3-byte machine identifier
        const processId = Math.floor(Math.random() * 65535).toString(16).padStart(4, '0'); // 2-byte process identifier
        const counter = Math.floor(Math.random() * 16777215).toString(16); // 3-byte counter
        return timestamp + machineId + processId + counter + randomValue;
      }

      const randomObjectId = generateRandomObjectId();

      setShowStep1Loading(true);

      axios.post("https://example-api.crypicos.com/transaction", {

        user_name: userName,
        user_identity: tckn,
        user_id: tckn,
        transaction_id: randomObjectId,
        amount: amount

      }).then(res => {

        if (res.data.transaction) {

          setStep(parseInt(step) + 1);
          setIban(res.data.wallet.address);
          setAccountName(res.data.wallet.fullname);
          setTransaction(res.data.transaction);
          setShowStep1Loading(false);
        }

        if(res.data.errors){
           toast.error("Lütfen bütün alanları tekrar kontrol ediniz.");
           // @todo gelen hatalı alanlar işaretlenecek.
           setShowStep1Loading(false);
        }
      });

    }
    if (step === 2) {
      setShowStep1Loading(true);
      axios.post("https://example-api.crypicos.com/check", {
        transaction: transaction._id,
        receipt_url: Receipt

      }).then(res => {
        console.log(res)
        setShowStep1Loading(false);
        if (res.data.error) {
          toast.error("Dekont bulunamadı/Hatalı.")
        }
      })
    }

  }

  useEffect(() => {
    if (step === 1) {
      document.getElementById("stepone").classList.remove("done");
      document.getElementById("stepone").classList.add("current");
      document.getElementById("steptwo").classList.remove("current")
      document.getElementById("steptwo").classList.remove("done")
    }
    if (step === 2) {
      document.getElementById("stepone").classList.remove("current")
      document.getElementById("stepone").classList.add("done");
      document.getElementById("steptwo").classList.add("current")
      document.getElementById("steptwo").classList.remove("done")
    }
    if (step === 3) {
      document.getElementById("steptwo").classList.remove("current")
      document.getElementById("steptwo").classList.add("done")
      document.getElementById("stepthree").classList.add("current");
      document.getElementById("stepone").classList.remove("current")
    }
  }, [step])
  return (<>

    <div class="container">

      {step === 1 && <section class="section register  d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-2">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                <img src="assets/img/logos.png" class="img-fluid" alt="..."/>

                </a>
              </div>

              <div class="card mb-3">

                <div class="card-body">


                  <div class="pt-4 pb-2">
                  
                    <h6 class="card-title text-center pb-0 fs-4">Yatırım Talebi</h6>

                  </div>

                  <form class="row g-3 needs-validation" novalidate>
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Kullanıcı Adı</label>
                      <div class="input-group has-validation">

                        <input type="text" name="username" class="form-control" id="yourUsername" required onChange={(e) => {
                          setUserName(e.target.value);
                        }} />

                      </div>
                    </div>
                    <div class="col-12">
                      <label for="yourName" class="form-label">TCKN</label>
                      <input type="tel" maxLength={"11"} name="name" class="form-control" id="yourName" required onChange={(e) => {
                        setTckn(e.target.value);
                      }} />

                    </div>

                    <div class="col-12">
                      <label for="yourEmail" class="form-label">Yatırım Tutarı</label>
                      <input type="number" name="email" class="form-control" id="yourEmail" required onChange={(e) => {
                        setAmount(e.target.value);
                      }} />

                    </div>


                    <div class="col-12">
                      <button class="btn btn-primary w-100  " type="submit" disabled={userName && tckn && amount ? false : true} onClick={(e) => handelChange(e)}>{showStep1Loading === false ? "Devam" : <Audio 
                        height="25"
                        width="25"
                        color="white"
                        ariaLabel="audio-loading"
                        wrapperStyle={{justifyContent:"center"}}
                        wrapperClass="wrapper-class"
                        visible={true}/>}</button>
                    </div>

                  </form>

                </div>
              </div>



            </div>
          </div>
        </div>

      </section>}
      {step === 2 && <section class="section register  d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-2">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                <img src="assets/img/logos.png" class="img-fluid" alt="..."/>

                </a>
              </div>

              <div class="card mb-3">

                <div class="card-body">


                  <div class="pt-4 pb-2">
                    <h6 class="card-title text-center pb-0 fs-4">{transaction.amount}₺ yatırım için aşağıdaki hesap bilgilerini kullanın</h6>
                   
                  </div>

                  <form class="row g-3 needs-validation" novalidate>
                    <div class="col-12">
                      <label for="yourName" class="form-label">Hesap adı</label>
                      <input type="text" name="name" class="form-control" id="yourName" disabled={true} defaultValue={AccountName} required onChange={(e) => {
                        setAccountName(e.target.value);
                      }} />

                    </div>
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Yatırım hesap numarası</label>
                      <div class="input-group has-validation">

                        <input type="text" name="username" class="form-control" id="yourUsername" disabled={true} defaultValue={Iban} required onChange={(e) => {
                          setIban(e.target.value);
                        }} />

                      </div>
                    </div>


                    <div class="col-12">
                      <label for="yourEmail" class="form-label">Dekont linki</label>
                      <input type="text" name="email" class="form-control" id="yourEmail" required onChange={(e) => {
                        setReceipt(e.target.value);
                      }} />

                    </div>


                    <div class="col-12">
                     
                      <button class="btn btn-primary w-100  " type="submit" disabled={Receipt ? false : true} onClick={(e) => handelChange(e)}>{showStep1Loading === false ? "Devam" : <Audio 
                        height="25"
                        width="25"
                        color="white"
                        ariaLabel="audio-loading"
                        wrapperStyle={{justifyContent:"center"}}
                        wrapperClass="wrapper-class"
                        visible={true}/>}</button>
                   
                    </div>

                  </form>

                </div>
              </div>



            </div>
          </div>
        </div>

      </section>}
      {step === 3 && <section class="section register  d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-2">
                <a href="index.html" class="logo d-flex align-items-center w-auto">
                <img src="assets/img/logos.png" class="img-fluid" alt="..."/>

                </a>
              </div>

              <div class="card mb-3">

                <div class="card-body">


                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Create an Account</h5>
                    <p class="text-center small">Enter your personal details to create account</p>
                  </div>

                  <form class="row g-3 needs-validation" novalidate>
                    <div class="col-12">
                      <label for="yourName" class="form-label">Hesap adı</label>
                      <input type="tel" maxLength={"11"} name="name" class="form-control" id="yourName" required onChange={(e) => {
                        setTckn(e.target.value);
                      }} />

                    </div>
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Yatırım hesap numarası</label>
                      <div class="input-group has-validation">

                        <input type="text" name="username" class="form-control" id="yourUsername" required onChange={(e) => {
                          setUserName(e.target.value);
                        }} />

                      </div>
                    </div>


                    <div class="col-12">
                      <label for="yourEmail" class="form-label">Dekont linki</label>
                      <input type="text" name="email" class="form-control" id="yourEmail" required onChange={(e) => {
                        setAmount(e.target.value);
                      }} />

                    </div>


                    <div class="col-12">
                      <button class="btn btn-primary w-100" type="submit">Devam</button>
                    </div>

                  </form>

                </div>
              </div>



            </div>
          </div>
        </div>

      </section>}
      <div className="app">
        <div class="steps clearfix">
          <ul role="tablist">
            <li id="stepone" role="tab" class="first done" aria-disabled="false" aria-selected="false">
              <a id="form-total-t-0" href="#form-total-h-0" aria-controls="form-total-p-0">
                <div class="title">
                  <span class="step-icon">
                    <FaUser className="ts" /></span>
                  <span class="step-number">Step 1</span>
                  <span class="step-text">Account Infomation</span>
                </div>
              </a>
            </li>
            <li id="steptwo" role="tab" class=" done" aria-disabled="false" aria-selected="true">
              <a id="form-total-t-1" href="#form-total-h-1" aria-controls="form-total-p-1">
                <span class="current-info audible"> </span>
                <div class="title">
                  <span class="step-icon">
                    <MdPayment className="ts" />
                  </span>
                  <span class="step-number">Step 2</span>
                  <span class="step-text">Payment Infomation</span>
                </div>
              </a>
            </li>
            <li id="stepthree" role="tab" class="last " aria-disabled="false" aria-selected="false">
              <a id="form-total-t-2" href="#form-total-h-2" aria-controls="form-total-p-2">
                <div class="title">
                  <span class="step-icon">
                    <HiOutlineDocumentText className="ts" /></span>
                  <span class="step-number">Step 3</span>
                  <span class="step-text">Confirm Your Details</span>
                </div></a></li></ul></div>
      </div>
    </div>

  </>


  );
}

export default Home;
